import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import { EuiSpacer } from '@elastic/eui';
import PTZTable from 'components/Frequently_Asked_Question/WQHD_Advanced_PTZ_Commands/PTZTable';
export const _frontmatter = {
  "title": "2K+ WQHD Extended PTZ Command Set",
  "path": "/Frequently_Asked_Question/WQHD_Advanced_PTZ_Commands/",
  "dateChanged": "2024-04-25",
  "author": "Mike Polinowski",
  "excerpt": "The CGI examples mentioned in the documentation under `ptzmove` unfortunately do not work with my IN-9420 2K+.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='2K+ WQHD Extended PTZ Command Set' dateChanged='2024-04-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The CGI examples mentioned in the documentation under `ptzmove` unfortunately do not work with my IN-9420 2K+.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WQHD_Advanced_PTZ_Commands/' locationFR='/fr/Frequently_Asked_Question/WQHD_Advanced_PTZ_Commands/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    
    <h1 {...{
      "id": "2k-wqhd-extended-ptz-command-set",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#2k-wqhd-extended-ptz-command-set",
        "aria-label": "2k wqhd extended ptz command set permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2K+ WQHD Extended PTZ Command Set`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: The CGI examples mentioned in the documentation under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ptzmove`}</code>{` unfortunately do not work with my IN-9420 2K+. With `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cmd=gotoRelPosition&pan=50&tilt=0`}</code>{`, for example, I get response code 217. Hardware not supported.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: The cameras of the 2K+ WQHD series have an extended CGI command set for panning, tilting, zooming and focusing compared to the previous Full HD, HD and VGA series. The `}<a parentName="p" {...{
        "href": "/en/1440p_Series_CGI_List/Features_Menu/PTZ/"
      }}>{`commands are documented in detail here`}</a>{`:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/1440p_Series_CGI_List/Features_Menu/PTZ/#paramcgicmdptzmove"
        }}>{`param.cgi?cmd=ptzmove`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/1440p_Series_CGI_List/Features_Menu/PTZ/#paramcgicmdgetptzstate"
        }}>{`param.cgi?cmd=getptzstate`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/1440p_Series_CGI_List/Features_Menu/PTZ/#paramcgicmdgotorelposition"
        }}>{`param.cgi?cmd=gotorelposition`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/1440p_Series_CGI_List/Features_Menu/PTZ/#paramcgicmdgetptzpreset"
        }}>{`param.cgi?cmd=getptzpreset`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/1440p_Series_CGI_List/Features_Menu/PTZ/#paramcgicmdsetptzpreset"
        }}>{`param.cgi?cmd=setptzpreset`}</a></li>
    </ul>
    <p>{`However, the implementations differ for indoor cameras (currently the IN-8415) and outdoor cameras (currently the IN-9420). With the latter, the status (the current position) of the Pan&Tilt cannot be read out and it is not possible to move a certain number of steps - i.e. movements must be stopped either by a subsequent stop command or by setting a time limit (otherwise the camera always moves to the hard stop!).`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PTZTable mdxType="PTZTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`Remark`}</strong>{`: The corresponding MQTT Topics are also affected: `}<a parentName="p" {...{
        "href": "https://forum.instar.com/t/firmware-update-3-0-0-733/29825/6"
      }}>{`see INSTAR Forum`}</a>{`.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a>{`
import { EuiSpacer } from "@elastic/eui/src/components/spacer/spacer"`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      